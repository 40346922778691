import React, { userState, useEffect, useState } from 'react'
import { Menu, MenuItem, Paper, Container, TableHead, TableBody, TableContainer, Button, Box, CardHeader, CardContent, Typography, TableCell, TableRow, Table, Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import axios from './../config'
import { useNavigate } from 'react-router-dom';
import AppLoading from './AppLoading';

const AppHome = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [rows, setRows] = useState([]);

    const user = JSON.parse(localStorage.getItem('user'));
    const [selectedFile, setSelectedFile] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!user) {
            navigate('/login');
        };
        setIsLoading(true);
        const token = user.accessToken;
        const formData = new FormData();
        formData.append('file', selectedFile);
        await axios.post('/file/upload', formData, { headers: { Authorization: token } })
            .then(response => {
                console.log('File uploaded successfully:', response.data);
                fetchPeople();
                setSelectedFile(null);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error uploading file:', error);
                localStorage.removeItem('user');
                setIsLoading(false);
            });
    };

    const handleLoginClick = () => {
        navigate('/login');
    }

    const handleLogoutClick = () => {
        localStorage.removeItem('user');
        navigate('/login');
    }

    const handleSendMailToAll = async () => {
        if (!user) {
            navigate('/login');
        };
        setIsLoading(true);
        const token = user.accessToken;
        console.log('Send mail to all');
        await axios
            .post("/people/send-mail-all",{}, { headers: { Authorization: token, 'Content-Type': 'application/json' } })
            .then((response) => {
                console.log(response.data);
                fetchPeople();
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('handleSendMailToAll:', error);
                localStorage.removeItem('user');
                setIsLoading(false);
            });
    };

    const handleSendMail = async (id) => {
        if (!user) {
            navigate('/login');
        };
        setIsLoading(true);
        const token = user.accessToken;
        const body = {
            id
        }
        await axios
            .post("/people/send-mail", body, { headers: { Authorization: token, 'Content-Type': 'application/json' } })
            .then((response) => {
                console.log(response.data);
                fetchPeople();
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('handleSendMail:', error);
                localStorage.removeItem('user');
                setIsLoading(false);
            });
    };

    const fetchPeople = async () => {
        setIsLoading(true);
        const token = user.accessToken;
        await axios
            .get("/people", { headers: { Authorization: token } })
            .then((response) => {
                console.log(response.data);
                setRows(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                localStorage.removeItem('user');
                navigate('/login');
                setIsLoading(false);
            });
    }
    useEffect(() => {
        console.log('use effect', user);
        if (user) {
            fetchPeople();
        }
    }, []);
    return (
        <div className='home'>
            <AppLoading isLoading={isLoading}></AppLoading>
            <Box sx={{ display: 'flex' }}>
                <Container maxWidth="xl">
                    <AppBar position="static">
                        <Toolbar>
                            <img src="/assets/logo.jpg" alt="Logo" style={{ height: '40px' }} />

                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Non Profit Help
                            </Typography>
                            {!user ? (
                                <Button color="inherit" onClick={handleLoginClick}>
                                    Login</Button>
                            ) : (
                                <div>
                                    <Button color="inherit" onClick={handleOpenUserMenu} sx={{ p: 0 }}>{user.userName}</Button>

                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <MenuItem key='key1' >
                                            <Typography textAlign="center" onClick={handleLogoutClick} >Logout</Typography>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            )}

                        </Toolbar>
                    </AppBar>
                    {user ? (
                        <div>
                            <Box sx={{ textAlign: 'left' }} >
                                <h2>File Upload</h2>
                                <input type="file" onChange={handleFileChange} />
                                <Button variant="outlined" onClick={handleUpload} disabled={!selectedFile}>Upload</Button>
                            </Box>
                            <br />
                            <Divider />
                            <br />
                            <Box sx={{ textAlign: 'left' }}><Button variant="outlined" onClick={() => handleSendMailToAll()}>Send Mail To All</Button></Box> <br />
                            <Divider />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell align="right">LastName</TableCell>
                                            <TableCell align="right">PhoneNumber</TableCell>
                                            <TableCell align="right">PickupDate</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.id}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell align="right">{row.last_name}</TableCell>
                                                <TableCell align="right">{row.phone_number}</TableCell>
                                                <TableCell align="right">{row.pickup_date}</TableCell>
                                                <TableCell align="right">
                                                    {!row.is_sent ? (<Button variant="outlined" onClick={() => handleSendMail(row.id)}>Send Mail</Button>) : (
                                                        <Button variant="outlined" color="secondary" onClick={() => handleSendMail(row.id)}>
                                                            Resend
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    ) : (
                        <h1> You need to login first</h1>
                    )}

                </Container>
            </Box>

        </div >
    )
}

export default AppHome
