import axios from "axios"

const instance = axios.create({
    // baseURL: "http://localhost:8080/api"
    baseURL: "https://nonprofit-api.phamiliartech.com/api"
});

instance.interceptors.response.use(
    response => response,
    error => {  
        if (error.response && 403 === error.response.status) {
            console.log('Got 403 response.')
            localStorage.clear();
            console.log('Local storage cleared');
            window.location.replace("/login");
          } else {
            return Promise.reject(error)
          }
    });


export default instance