import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

const AppLoading = ({ isLoading }) => {
  return (
    <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
      <CircularProgress color="primary" size={60} />
    </Backdrop>
  );
};

export default AppLoading;