import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppHome from './components/AppHome';
import AppLogin from './components/AppLogin';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/">
      <Route index element={<AppHome />} />
      <Route path="/login" element={<AppLogin />} />
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
