import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { CssBaseline, Box, Container, Typography, Grid, TextField, FormControlLabel, Checkbox, Button, Link, Paper } from '@mui/material'
import axios from './../config';

const AppLogin = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const userInfo = {
        email: data.get('email'),
        password: data.get('password'),
      };

      console.log(userInfo);
      await axios
      .post("/user/login", userInfo)
      .then((response) => {
          console.log(response.data);
          localStorage.setItem("user", JSON.stringify(response.data));
          navigate("/");

      })
      .catch((error) => {
      });

    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <Box>
       <Container maxWidth="xl">
        <CssBaseline />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Log in
              </Button>
              
            </Box>
          </Box>

      </Container>
    </Box>
  )
}

export default AppLogin
